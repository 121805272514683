import React, { useState, useEffect } from 'react'
import { Modal, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import Axios from 'axios'
import moment from 'moment'
import baseURL from './config.js';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import 'moment/locale/ja'

export const Gensen = () => {
  const [activeuserList, setActiveUserList] = useState([]);
  const [entryCount, setEntryCount] = useState(0);
  const [hakkouTimer, setHakkouTimer] = useState(false);
  const [isHakkouEnabled, setIsHakkouEnabled] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [filename, setFilename] = useState("");
  const [notInReg, setNotInReg] = useState("");
  const [notInCSV, setNotInCSV] = useState("");
  const [rowData, setRowData] = useState(null);
  const [isDataInCurrentMonth, setIsDataInCurrentMonth] = useState(false);
  const [notInRegErr, setNotInRegErr] = useState("");
  const [notInCSVErr, setNotInCSVErr] = useState("");
  const [succ, setSucc] = useState("");
  const currentDate = moment().format('YYYY-MM-DD')
  const [showPopup, setShowPopup] = useState(false);
  const [specificDay, setSpecificDay] = useState('');
  const [specificTime, setSpecificTime] = useState('');

  const [defaultTime, setDefaultTime] = useState('');
  const [defaultDay, setDefaultDay] = useState('');
  const [showSpecificDateTime, setShowSpecificDateTime] = useState(false);


  useEffect(() => {
    // Retrieve states from localStorage
    const savedFilename = localStorage.getItem('g_filename');
    const savedNotInReg = JSON.parse(localStorage.getItem('g_notInReg'));
    const savedNotInCSV = JSON.parse(localStorage.getItem('g_notInCSV'));
    const savedEntryCount = parseInt(localStorage.getItem('g_entryCount'), 10);
    const savedFileUploaded = localStorage.getItem('g_fileUploaded') === 'true';

    // Initialize states with saved values
    setFilename(savedFilename || '');
    setNotInReg(savedNotInReg || []);
    setNotInCSV(savedNotInCSV || []);
    setEntryCount(savedEntryCount || 0);
    setFileUploaded(savedFileUploaded || false);
    Axios.get(`${baseURL}/api/employee`)
      .then((response) => {
        const { activeEmployee } = response.data;
        setActiveUserList(activeEmployee);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    Axios.get(`${baseURL}/api/gensen/rireki`)
      .then((response) => {
        const { rireki } = response.data;
        const current = moment().format('YYYY-MM-DD HH:mm:ss');

        // Find the data of the row in the current month
        const rowData = rireki.find((item) => moment(item.hakkoudate).isAfter(moment(current)));
        if (rowData) {
          setRowData(rowData);
          setIsDataInCurrentMonth(true);
          setHakkouTimer(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (fileUploaded) {
      if (notInCSV.length === 0 && notInReg.length === 0) {
        setIsHakkouEnabled(true);
      }
    }
  }, [notInCSV, notInReg, fileUploaded]);

  useEffect(() => {
    Axios.get(`${baseURL}/api/gensen/schedule`)
      .then((response) => {
        const { defaultSchedule } = response.data;

        if (defaultSchedule && defaultSchedule.length > 0) {
          const defaultDate = moment(defaultSchedule[0].date);
          const now = moment();

          // Check if the default schedule has already passed
          if (defaultDate.isBefore(now)) {
            // If yes, add 1 month to the default date
            defaultDate.add(1, 'month');
          }

          setDefaultDay(defaultDate.format('YYYY-MM-DD'));
          setDefaultTime(defaultDate.format('HH:mm'));
        } else {
          // If there is no data or defaultSchedule is empty, set the default date and time to the current date and time
          const now = moment();
          setDefaultDay(now.format('YYYY-MM-DD'));
          setDefaultTime(now.format('HH:mm'));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (fileUploaded) {
      if (notInCSV.length === 0 && notInReg.length === 0) {
        setSucc('アプロード成功しました');
        setNotInCSVErr("");
        setNotInRegErr("");
      }
      if (notInCSV.length !== 0) {
        const error = `CSVファイルには登録ユーザーリストに存在するはずのエントリーが${notInCSV.length}つ欠落しています。`;
        const errorEntries = notInCSV.map(value => ` No.${value}`).join(',');
        setNotInCSVErr(`${error}(${errorEntries})`);
        setSucc("");
        setNotInRegErr("");
        console.log(notInCSV);
        console.log(notInCSVErr);
      }

      if (notInReg.length !== 0) {
        const error = `CSVファイルのエントリーは、アプリケーション内に対応するアクティブなユーザーアカウントが存在しません。`;
        const errorEntries = notInReg.map(value => ` No.${value}`).join(',');
        setNotInRegErr(`${error}(${errorEntries})`);
        setSucc("");
        setNotInCSVErr("");
        console.log(notInReg);
        console.log(notInRegErr);
      }

      if (notInCSV.length !== 0 && notInReg.length !== 0) {
        const csvError = `CSVファイルには登録ユーザーリストに存在するはずのエントリーが${notInCSV.length}つ欠落しています。`;
        const csvErrorEntries = notInCSV.map(value => ` No.${value}`).join(',');
        const regError = `CSVファイルのエントリーは、アプリケーション内に対応するアクティブなユーザーアカウントが存在しません。`;
        const regErrorEntries = notInReg.map(value => ` No.${value}`).join(',');
        setNotInCSVErr(`${csvError}(${csvErrorEntries})`);
        setNotInRegErr(`${regError}(${regErrorEntries})`);
        setSucc("");
        console.log(notInReg);
        console.log(notInRegErr);
      }
    }
  }, [fileUploaded, notInCSV, notInReg, succ, notInRegErr, notInCSVErr]);

  function toggleSpecificDateTime() {
    setShowSpecificDateTime(!showSpecificDateTime);

    // Reset specificDay and specificTime when showSpecificDateTime is toggled to true
    if (showSpecificDateTime) {
      setSpecificDay('');
      setSpecificTime('');
    }
  }

  function handleButtonClick() {
    document.getElementById('fileInput').click();
  }

  function togglePopup() {
    setShowPopup(!showPopup);
  }

  function handleHakkou() {
    setHakkouTimer(true);

    const payload = {
      shousai: "源泉徴収票発行タイマーがセットされました。"
    };

    Axios.post(`${baseURL}/api/sousa`, payload)
      .then((response) => {
        // Handle successful form submission
        const successAlert = document.createElement('div');
        successAlert.classList.add('alert', 'alert-success');
        successAlert.textContent = '源泉徴収票発行タイマーがセットされました';
        document.body.appendChild(successAlert);
        setTimeout(() => {
          document.body.removeChild(successAlert);
        }, 3000);
        console.log(response);
      })
      .catch((error) => {
        console.log('Error in post request:', error);
      });

    // Check if specific date and time are provided, otherwise use the default ones
    const selectedDay = specificDay || defaultDay;
    const selectedTime = specificTime || defaultTime;
    const selectedDateTime = moment(`${selectedDay} ${selectedTime}`).format('YYYY-MM-DD HH:mm');

    let cronSchedule;
    // cronSchedule = moment(`${selectedDay} ${selectedTime}`).format('mm HH DD MM *');

    if (specificDay && specificTime) {
      // If specificDay and specificTime are provided, use the selectedDateTime as the cronSchedule
      cronSchedule = moment(`${selectedDay} ${selectedTime}`).format('mm HH DD MM *');
    } else {
      // For monthly repetition, use the selected day of the month and the time
      cronSchedule = ` ${selectedTime.split(':')[1]} ${selectedTime.split(':')[0]} ${selectedDay.split('-')[2]} ${selectedDay.split('-')[1]} *`;
    }

    Axios.post(`${baseURL}/api/gensen/schedule`, { selectedDateTime })
      .then((response) => {
        console.log('Schedule successful:', response.data);
        // You can perform any additional actions here upon successful scheduling.
      })
      .catch((error) => {
        console.error('Error scheduling:', error.response.data);
        // Handle the error or display an error message to the user.
      });


    Axios.post(`${baseURL}/api/gensen/hakkou`, { selectedDateTime: cronSchedule })
      .then((response) => {
        removeLocalStorage();
        console.log(response);
        console.log('Post request successful');

      })
      .catch((error) => {
        console.log('Error in post request:', error);
        console.log(selectedDateTime);
      });

    Axios.post(`${baseURL}/api/gensen/rireki`, {
      succ,
      notInCSVErr,
      notInRegErr,
      filename,
      entryCount,
      date: selectedDateTime,
    })
      .then((response) => {
        removeLocalStorage()
        console.log('Post request successful');
      })
      .catch((error) => {
        console.log('Error in post request:', error);
      });

    setShowPopup(false);

  }

  function handleCancel() {
    // Close the modal
    setShowPopup(false);
  }

  function cancelHakkou(event) {
    event.preventDefault();

    Axios.delete(`${baseURL}/api/gensen/hakkou`)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log('Error in delete request:', error);
      });

    setHakkouTimer(false);
    setFileUploaded(false);
    setIsHakkouEnabled(false);
    setIsDataInCurrentMonth(false);



    const payload = {
      shousai: "源泉発行タイマーがキャンセルされました。",
      date: currentDate,
    };


    Axios.post(`${baseURL}/api/sousa`, payload)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log('Error in post request:', error);
      });


  }

  const handleRemoveFile = () => {
    if (hakkouTimer) {
      Axios.delete(`${baseURL}/api/gensen/hakkou`)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log('Error in delete request:', error);
        });

      setHakkouTimer(false);
      setFileUploaded(false);
      setIsHakkouEnabled(false);
      setIsDataInCurrentMonth(false);
      removeLocalStorage()

      const payload = {
        shousai: "源泉発行タイマーがキャンセルされました。",
        date: currentDate,
      };

      Axios.post(`${baseURL}/api/sousa`, payload)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log('Error in post request:', error);
        });
    } else {
      setFileUploaded(false);
      setHakkouTimer(false);
      setIsHakkouEnabled(false);
      setIsDataInCurrentMonth(false);
      removeLocalStorage()
    }
  };

  function handleDragOver(event) {
    event.preventDefault();
  }

  function handleChange(event) {
    event.preventDefault();
    const files = event.target.files;
    processCSVFile(files[0]);
  }

  function handleDrop(event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    processCSVFile(files[0]);
  }

  function processCSVFile(file) {
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // Maximum file size in bytes (e.g., 5MB)

    if (file.size > MAX_FILE_SIZE) {
      alert('File size exceeds the allowed limit.');
      return;
    }

    if (file.type !== 'text/csv') {
      alert('Invalid file format. Only CSV files are allowed.');
      return;
    }

    const formData = new FormData();
    formData.append('csvFile', file);

    const reader = new FileReader();
    reader.onload = (e) => {
      const csvData = e.target.result;
      const expectedColumns = ['id', 'bangou', 'kihon', 'teate', 'kenkou', 'nenkin', 'koyou'];
      const rows = csvData.split('\n').map((row) => row.trim());
      const headers = rows[0].split(',');

      // Check if CSV has the expected number of columns and correct column names
      if (headers.length !== expectedColumns.length || !expectedColumns.every((col) => headers.includes(col))) {
        alert('Invalid CSV format. Please make sure the CSV file has the correct headers.');
        return;
      }

      setFilename(file.name);

      const csvEntries = rows.slice(1).map((row) => {
        const values = row.split(',');
        return headers.reduce((entry, header, index) => {
          entry[header] = values[index];
          return entry;
        }, {});
      });

      const csvBangouList = csvEntries.map((entry) => entry['bangou']).filter(Boolean);
      const activeUserBangouList = activeuserList.map((employee) => employee.bangou).filter(Boolean);
      const unknownBangouList = csvBangouList.filter((bangou) => !activeUserBangouList.includes(bangou));
      setNotInReg(unknownBangouList);

      const missingBangouList = activeUserBangouList.filter((bangou) => !csvBangouList.includes(bangou));
      setNotInCSV(missingBangouList);

      const count = csvEntries.length - 1;
      setEntryCount(count);
      setFileUploaded(true);

      localStorage.setItem('g_filename', file.name);
      localStorage.setItem('g_notInReg', JSON.stringify(unknownBangouList));
      localStorage.setItem('g_notInCSV', JSON.stringify(missingBangouList));
      localStorage.setItem('g_entryCount', count.toString());
      localStorage.setItem('g_fileUploaded', 'true');

      // Make the API call to upload the CSV file
      Axios.post(`${baseURL}/api/gensen`, formData)
        .then((response) => {
          console.log(response.data);
          // Handle successful response
        })
        .catch((error) => {
          console.log(error);
          // Handle error
        });
    };

    reader.readAsText(file);
  }

  function removeLocalStorage() {
    localStorage.removeItem('g_filename');
    localStorage.removeItem('g_notInReg');
    localStorage.removeItem('g_notInCSV');
    localStorage.removeItem('g_entryCount');
    localStorage.removeItem('g_fileUploaded');
  }


  const handleDateChange = (event) => {
    const day = parseInt(event.target.value, 10);
    const currentMoment = moment();
    const currentMonth = currentMoment.month();
    const scheduledDateTime = moment(
      `${currentMoment.year()}-${currentMonth + 1}-${day} ${defaultTime}`,
      'YYYY-MM-DD HH:mm'
    );
    console.log(scheduledDateTime)


    // Check if the selected date and time are in the past
    if (scheduledDateTime < currentMoment) {
      // If both day and time are in the past, increment the month by one
      scheduledDateTime.add(1, 'month');
    }
    console.log(scheduledDateTime.format('YYYY-MM-DD'))
    // Set the defaultDay and defaultTime to the formatted date and time
    setDefaultDay(scheduledDateTime.format('YYYY-MM-DD'));
  };

  const handleTimeChange = () => {
    const currentMoment = moment();
    const currentMonth = currentMoment.month();
    const day = moment(defaultDay).format('DD')
    const scheduledDateTime = moment(
      `${currentMoment.year()}-${currentMonth + 1}-${day} ${defaultTime}`,
      'YYYY-MM-DD HH:mm'
    );


    // Check if the selected date and time are in the past
    if (scheduledDateTime < currentMoment) {
      // If both day and time are in the past, increment the month by one
      scheduledDateTime.add(1, 'month');
    }

    // Set the defaultDay and defaultTime to the formatted date and time
    setDefaultDay(scheduledDateTime.format('YYYY-MM-DD'));
  };

  const isValidDate = (current) => {
    // Disable dates and times that have already passed
    return current.isAfter(moment().subtract(1, 'day')); // Allow only dates and times in the future
  };

  const specificDateChange = (selectedDate) => {
    // When using react-datetime, the selected date is already provided as an argument
    // in the `handleDateChange` function, so we can directly use it to update the state.
    setSpecificDay(selectedDate.format('YYYY-MM-DD'));

  };

  const specificTimeChange = (selectedTime) => {
    // When using react-datetime, the selected time is already provided as an argument
    // in the `handleTimeChange` function, so we can directly use it to update the state.
    setSpecificTime(selectedTime.format('HH:mm'));

  };

  return (
    <div className='bo'>
      <h4 className='fo-t'>源泉徴収票</h4>

      <div className='row bt-r' style={{ width: '35%', backgroundColor: 'white', marginLeft: '1px', padding: '5px' }}>
        <div className='col'>
          <div className='row'>
            <div className='col-2'><h3><i className="fa-regular fa-user" style={{ color: "rgba(16, 12, 196, 0.76)" }}></i></h3></div>
            <div className='col-10'>　<i className="fa-solid fa-circle" style={{ color: "#19E415" }}></i>アクティブ<br />　{activeuserList.length}</div>
          </div>
        </div>

        <div className='col-1'><h2 style={{ fontWeight: 'lighter' }}>|</h2></div>

        <div className='col'>
          <div className='row'>
            <div className='col-2'><h3><i className="fa-solid fa-hand-holding-dollar" style={{ color: "rgba(16, 12, 196, 0.76)" }}></i></h3></div>
            <div className='col-10'>　源泉徴収票数<br />　{isDataInCurrentMonth ? rowData.entryCount : entryCount}</div>
          </div>
        </div>
      </div>

      <div>
        {isDataInCurrentMonth ?
          (<>
            <h5 style={{ display: 'inline' }} className='csv-filename'>{rowData.filename}</h5>
            <button className="btn" onClick={handleRemoveFile}>✕</button>
            {
              rowData.message3 !== null && rowData.message3 !== "" ? (
                <div className='bt-r csvupload'>
                  <p className='success'>{rowData.message3}</p>
                </div>
              ) : (
                <div className='bt-r csvupload'>
                  <p className='warning'>データとの間に不一致が見つかりました:</p>
                  {rowData.message1 !== null && rowData.message1 !== "" && (
                    <p className='warning'>{rowData.message1}</p>
                  )}
                  {rowData.message2 !== null && rowData.message2 !== "" && (
                    <p className='warning'>{rowData.message2}</p>
                  )}
                </div>
              )

            } </>) : (!fileUploaded ? (
              <>
                <h5>*NO CSV*</h5>
                <div className="bt-r csvupload" onDragOver={handleDragOver} onDrop={handleDrop}>
                  ドラックアンドドロップするとファイルアップロードできます。
                  <div>
                    <input type="file" id="fileInput" style={{ display: 'none' }} onChange={handleChange} />
                    <button onClick={handleButtonClick} className="bt-csv">
                      ✚ CSV アプロード
                    </button>
                  </div>
                </div>
              </>
            ) : notInCSV.length === 0 && notInReg.length === 0 ? (
              <>
                <h5 style={{ display: 'inline' }} className='csv-filename'>{filename}</h5>
                <button className="btn" onClick={handleRemoveFile}>✕</button>
                <div className='bt-r csvupload'>
                  <h5 className='success'>アプロード成功しました　✓</h5>
                </div>
              </>
            ) : (
              <>
                <h5 style={{ display: 'inline' }} className='csv-filename'>{filename}</h5>
                <button className="btn" onClick={handleRemoveFile}>✕</button>
                <div className='bt-r csvupload'>
                  <p className='warning'>データとの間に不一致が見つかりました:</p>
                  {notInCSV.length !== 0 && <p className='warning'>CSVファイルには登録ユーザーリストに存在するはずのエントリーが欠落しています。<br />
                    {notInCSV.map((value, index) => (
                      <span key={index}>{` No.${value}`}</span>
                    ))}</p>
                  }
                  {notInReg.length !== 0 && <p className='warning'>CSVファイルのエントリーは、アプリケーション内に対応するアクティブなユーザーアカウントが存在しません。<br />
                    {notInReg.map((value, index) => (
                      <span key={index}>{` No.${value}`}</span>
                    ))}</p>
                  }
                  {!isHakkouEnabled && (
                    <div>
                      <p className='warning'>続行してもよろしいですか？</p>
                      <button className="btn btn-primary" style={{ marginRight: '10px' }} onClick={() => setIsHakkouEnabled(true)}>はい</button>
                      <button className="btn btn-primary" onClick={handleRemoveFile}>いいえ</button>
                    </div>
                  )}
                </div>
              </>
            ))}
      </div>

      <Link to="/gensen/rireki" style={{ textDecoration: 'underline', flex: 1 }}>発行履歴をみる</Link>

      <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '60px' }}>
        {hakkouTimer && 
          <h5 style={{ border: '2px solid gray', borderRadius: '5px', padding: '12px 10px', display: 'inline', marginTop: '10px', marginRight: '5px' }}>
          {/* 次回発行日時：　{specificDay}　{specificTime} */}
          次回発行日時：{specificDay || defaultDay} {specificTime || defaultTime}
          </h5>
          }
        {!hakkouTimer ? <button className='bt-c' disabled={!isHakkouEnabled} onClick={() => togglePopup()}>発行タイマーセット</button> : <button className='bt-c' style={{ backgroundColor: '#bc2a1a' }} onClick={cancelHakkou}>発行タイマーキャンセル</button>}
      </div>


      {/* 発行タイマーのデザイン変更用対応 */}
      {showPopup && (
        <Modal show={showPopup} onHide={() => setShowPopup(false)} centered>
          <Modal.Header closeButton style={{ backgroundColor: '#DDEFFF', color: '#4f4f4f' }}>
            <Modal.Title>発行タイマーセット</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Default Day and Time Section */}
            <Form style={{ color: '#4f4f4f' }}>
              <h5 style={{ marginTop: '10px', padding: '5px', textAlign: 'center' }}>デフォルト日時を設定</h5>
              <div style={{ display: 'flex' }}>
                <Form.Group controlId="defaultDayPicker" style={{ display: 'flex', alignItems: 'center', flex: 1, margin: '10px 0 0' }}>
                  {/* Use react-datetime for date picking */}
                  日付
                  <input
                    type="number"
                    min="1"
                    max="31"
                    value={parseInt(moment(defaultDay).format('DD'), 10)} // Extract day from the defaultDay
                    onChange={handleDateChange}
                    placeholder="日付"
                    style={{ display: 'inline-block', textAlign: 'center', borderRadius: '5px', padding: '5px', border: '1px solid #dee2e6' }}
                  />日

                </Form.Group>
                <Form.Group controlId="defaultTimePicker" style={{ marginTop: '10px', display: 'flex', alignItems: 'center', width: "50%" }}>
                  {/* Use react-datetime for time picking */}
                  時間
                  <Datetime
                    dateFormat={false}
                    timeFormat="HH:mm"
                    value={defaultTime}
                    onChange={(time) => {
                      // Set the time using moment.js
                      setDefaultTime(moment(time).format('HH:mm'));
                      handleTimeChange();
                    }}
                    inputProps={{ placeholder: '時間' }}
                  />
                </Form.Group>
              </div>
            </Form>
            <hr />

            <h5 style={{ textAlign: 'center', cursor: 'pointer', color: '#007bff' }} onClick={toggleSpecificDateTime}>次回発行日のみの変更</h5>

            {/* Specific Date and Time Section */}
            {showSpecificDateTime && (
              <Form>
                <Form.Group controlId="specificDatePicker">

                  {/* Use react-datetime for date picking */}
                  <Datetime
                    dateFormat="YYYY-MM-DD"
                    timeFormat={false}
                    value={specificDay ? specificDay : moment().format('YYYY-MM-DD')}
                    onChange={specificDateChange}
                    locale="ja" // Set the locale to Japanese
                    inputProps={{ placeholder: '日付を選択してください' }} // Set placeholder in Japanese
                    isValidDate={isValidDate} // Set the isValidDate prop
                  />
                </Form.Group>

                <Form.Group controlId="specificTimePicker" style={{ marginTop: '10px' }}>
                  {/* Use react-datetime for time picking */}
                  <Datetime
                    dateFormat={false}
                    timeFormat="HH:mm"
                    value={specificTime ? specificTime : moment().format('HH:mm')}
                    onChange={specificTimeChange}
                    inputProps={{ placeholder: '時間を選択してください' }} // Set placeholder in Japanese
                    isValidDate={isValidDate} // Set the isValidDate prop
                  />
                </Form.Group>
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleHakkou}>発行</Button>
            <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>


  );
};
