import React from 'react'

export const EGensen = () => {
  return (
    <div className='bg'>
      <div className='bt-t' style={{ backgroundColor: 'white', textAlign: "left" }}>
        <div className="logo">
          <div className="left" style={{ color: "rgba(16, 12, 196, 0.76)" }}>Q</div>
          <div className="right">
            <div style={{ color: "rgba(16, 12, 196, 0.76)" }}>給与明細システム</div>
          </div>
        </div>
      </div>
      <div className='bo' style={{textAlign:"center"}}>
      <img src={process.env.PUBLIC_URL + '/源泉徴収票.png'} alt="源泉" />
      </div>
      </div>
  )
}
