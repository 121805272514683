import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom'
import baseURL from './config.js';
import moment from 'moment'

export const Seikyuusho = () => {
  const [seikyuushoList, setSeikyuushoList] = useState([]);
  const [searchMonth, setSearchMonth] = useState('');
  const [searchYear, setSearchYear] = useState('');
  const userId = 43;

  // カンマ区切り対応の為const->letに変更
  // const kingaku = 30000;
  let kingaku = 30000;

  // 数値をカンマ区切り対応
  kingaku = Number(kingaku).toLocaleString();
  

  useEffect(() => {
    Axios.get(`${baseURL}/api/rireki?id=${userId}`)
      .then((response) => {
        const { seikyuushoRireki } = response.data;
        setSeikyuushoList(seikyuushoRireki);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userId]);

  const filteredUserList = seikyuushoList.filter((val) => {
    const { hakkouBangou, date } = val;

    const fullDate = new Date(date);
    const searchMonthNumber = parseInt(searchMonth);
    const searchYearNumber = parseInt(searchYear);

    const isMonthMatch = isNaN(searchMonthNumber) || fullDate.getMonth() + 1 === searchMonthNumber;
    const isYearMatch = isNaN(searchYearNumber) || fullDate.getFullYear() === searchYearNumber;

    return (isMonthMatch && isYearMatch);
  });


  ///----------------->>>>DATEPICKER
  const handleMonthChange = (e) => {
    setSearchMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    setSearchYear(e.target.value);
  };

  const generateMonths = () => {
    const months = [];
    for (let i = 1; i <= 12; i++) {
      const month = {
        value: i.toString(),
        label: new Date(0, i - 1).toLocaleString('ja-JP', { month: 'long' }),
      };
      months.push(month);
    }
    return months;
  };

  const generateYears = (startYear, endYear) => {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      const yearObj = {
        value: year.toString(),
        label: year.toString(),
      };
      years.push(yearObj);
    }
    return years;
  };

  const currentYear = new Date().getFullYear();
  const months = generateMonths();
  const years = generateYears(2021, currentYear); // input start of year selection here

  ///----------------->>>>

  return (
    <div className='bo'>
      <h4 className='fo-t'>請求書履歴</h4>
      <div className='bt-j'>
        <div className='bt-s' style={{ display: 'inline' }}>
          <select value={searchMonth} onChange={handleMonthChange} className='datepicker rounded-left' >
            <option value="">月</option>
            {months.map((month) => (
              <option key={month.value} value={month.value}>
                {month.label}
              </option>
            ))}
          </select>

          <select value={searchYear} onChange={handleYearChange} className='datepicker rounded-right'>
            <option value="">年</option>
            {years.map((year) => (
              <option key={year.value} value={year.value}>
                {year.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div style={{ backgroundColor: "white", borderRadius: "10px", boxShadow: "3px 3px 6px rgba(90, 140, 168, 0.5)", paddingBottom: "30px", marginRight: "20px", marginBottom: "120px" }}>
        <table>
          <thead className='mi-d'>
            <tr>
              <th style={{ padding: "20px" }}>NO.</th>
              <th style={{ padding: "20px" }}>日付</th>
              <th style={{ padding: "20px" }}>金額</th>
              <th style={{ padding: "20px" }}></th>
            </tr>
          </thead>
          <tbody>
            {filteredUserList.reverse().map((val, index) => {
              return (
                <tr key={val.id}>
                  <td style={{ padding: "10px" }}>{index + 1}</td>
                  <td style={{ padding: "10px" }}>{moment(val.hakkoudate).format('YYYY-MM-DD')}</td>
                  <td style={{ padding: "10px" }}>{kingaku}</td>
                  <td style={{ padding: "10px" }}>
                    <Link to={`/seikyuusho/shousai?hakkou=${encodeURIComponent(val.hakkouBangou)}`}>
                      <button className="bt-h" style={{ color: "#000000c6" }}>詳細</button>
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

    </div>
  );
};
