import { useRef, useState, useEffect } from 'react';
import Axios from 'axios';
import useAuth from './useAuth';
import { Link, useNavigate } from 'react-router-dom';
import baseURL from './config.js';

const Login = () => {
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const [loginRole, setLoginRole] = useState(''); // 初期値を空に設定
  const path = loginRole === 'employee' ? '/employee/dashboard' : '/home';

  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');
  const [errMsg, setErrMsg] = useState('');

  useEffect(() => {
    userRef.current.focus();
  }, [])

  useEffect(() => {
    setErrMsg('');
  }, [user, pwd])

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let url;
      if (loginRole === 'employee') {
        url = `${baseURL}/api/employee/login`;
      } else {
        // url = `${baseURL}/api/admin/login`;
        url = `${baseURL}/api/company/login`;
      }

      const response = await Axios.post(url, { user, pwd });
      const token = response.data.token;
      const role = response.data.role;
      const bangou = response.data.bangou;
      // Store the token and role in the authentication context
      bangou ? setAuth({ token, role, bangou }) : setAuth({ token, role });

      setUser('');
      setPwd('');
      navigate(path, { replace: true });
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrMsg('Missing Username or Password');
      } else if (err.response?.status === 401) {
        setErrMsg('Unauthorized');
      } else {
        setErrMsg('Login Failed');
      }
      errRef.current.focus();
    }
  }

  // ユーザーの入力に基づいて loginRole を設定
  useEffect(() => {
    if (user.includes('@')) {
      setLoginRole('employee');
    } else {
      setLoginRole('admin');
    }
  }, [user]);

  return (
    <div className="bg">
      <div className="bt-t" style={{ backgroundColor: 'white', textAlign: 'left' }}>
        <div className="logo">
          <div className="left" style={{ color: 'rgba(16, 12, 196, 0.76)' }}>Q</div>
          <div className="right">
            <div style={{ color: 'rgba(16, 12, 196, 0.76)' }}>給与明細システム</div>
          </div>
        </div>
      </div>
      <div className="fcon" style={{ width: '40%', margin: '20px auto', padding: '20px 7%' }}>
        <section>
          <p ref={errRef} className={errMsg ? 'errmsg' : 'offscreen'} aria-live="assertive">{errMsg}</p>
          <h4 style={{ textAlign: 'center' }}>Log In</h4>
          <form onSubmit={handleSubmit}>
            <div style={{ paddingTop: '20px' }}>
              <label htmlFor="username" className="form-label">Username:</label>
              <input
                type="text"
                id="username"
                ref={userRef}
                autoComplete="off"
                onChange={(e) => setUser(e.target.value)}
                value={user}
                required
                className="form-control"
              />
            </div>
            <div style={{ paddingTop: '20px' }}>
              <label htmlFor="password" className="form-label">Password:</label>
              <input
                type="password"
                id="password"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                required
                className="form-control"
              />
            </div>
            <button type="submit" className="btn btn-primary" style={{ padding: '10px 30px', marginTop: '20px' }}>Sign In</button>
          </form>
          <p>
            Need an Account?<br />
            <span className="line">
              <Link to="/register">Sign Up</Link>
            </span>
          </p>
        </section>
      </div>
    </div>
  );
};
export default Login;