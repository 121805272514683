import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "./useAuth";


const RequireAuth = ({ allowedRoles }) => {
  const { auth } = useAuth();
  const location = useLocation();

  if (!auth) {
    // User is not authenticated, redirect to login
    return (
      <Navigate to="/login" state={{ from: location }} replace />
    );
  }

  const userRole = parseInt(auth.role);

  if (allowedRoles.includes(userRole)) {
    // User has allowed role, render the component
    return <Outlet />;
  } else {
    // User does not have allowed role, redirect to login
    return (
      <Navigate to="/unauthorized" state={{ from: location }} replace />
    );
  }
};

export default RequireAuth;