import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';
import baseURL from './config.js';
import moment from 'moment';

export const Juugyouin = () => {
  const [userList, setUserList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [employeeData, setEmployeeData] = useState(null);
  const [status, setStatus] = useState('Active');
  const [nyuusha, setNyuusha] = useState('');
  const [taishoku, setTaishoku] = useState('');
  const [email, setEmail] = useState('');
  const [bangou, setBangou] = useState('');
  const [searchMonth, setSearchMonth] = useState('');
  const [searchYear, setSearchYear] = useState('');
  const [showRetirementDate, setShowRetirementDate] = useState(false);
  const [filename, setFilename] = useState("");
  const [activeuserList, setActiveUserList] = useState([]);
  const [notInReg, setNotInReg] = useState("");
  const [notInCSV, setNotInCSV] = useState("");
  const [entryCount, setEntryCount] = useState(0);
  const [fileUploaded, setFileUploaded] = useState(false);




  useEffect(() => {
    fetchEmployeeData();
  }, []);

  const fetchEmployeeData = () => {
    Axios.get(`${baseURL}/api/employee`)
      .then((response) => {
        const { employee } = response.data;
        setUserList(employee);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const filteredUserList = userList.filter((val) => {
    const { status, email, bangou, nyuusha } = val;
    const searchRegex = new RegExp(searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');

    const fullDate = new Date(nyuusha);
    const searchMonthNumber = parseInt(searchMonth);
    const searchYearNumber = parseInt(searchYear);

    const isMonthMatch = isNaN(searchMonthNumber) || fullDate.getMonth() + 1 === searchMonthNumber;
    const isYearMatch = isNaN(searchYearNumber) || fullDate.getFullYear() === searchYearNumber;

    return (
      (searchTerm === '' || searchRegex.test(status) || searchRegex.test(email) || searchRegex.test(bangou.toString())) &&
      isMonthMatch && isYearMatch
    );
  });

  ///----------------->>>>DATEPICKER

  const handleMonthChange = (e) => {
    setSearchMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    setSearchYear(e.target.value);
  };

  const generateMonths = () => {
    const months = [];
    for (let i = 1; i <= 12; i++) {
      const month = {
        value: i.toString(),
        label: new Date(0, i - 1).toLocaleString('ja-JP', { month: 'long' }),
      };
      months.push(month);
    }
    return months;
  };

  const generateYears = (startYear, endYear) => {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      const yearObj = {
        value: year.toString(),
        label: year.toString(),
      };
      years.push(yearObj);
    }
    return years;
  };

  const currentYear = new Date().getFullYear();
  const months = generateMonths();
  const years = generateYears(2021, currentYear); // input start of year selection here

  ///----------------->>>>

  function handleButtonClick() {
    document.getElementById('fileInput').click();
  }

  function handleChange(event) {
    event.preventDefault();
    const files = event.target.files;
    processCSVFile(files[0]);
  }

  function processCSVFile(file) {
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // Maximum file size in bytes (e.g., 5MB)

    if (file.size > MAX_FILE_SIZE) {
      alert('File size exceeds the allowed limit.');
      return;
    }

    if (file.type !== 'text/csv') {
      alert('Invalid file format. Only CSV files are allowed.');
      return;
    }

    const formData = new FormData();
    formData.append('csvFile', file);

    const reader = new FileReader();
    reader.onload = (e) => {
      const csvData = e.target.result;
      const expectedColumns = ['id', 'bangou', 'email', 'nyuusha', 'taishoku', 'status'];
      const rows = csvData.split('\n').map((row) => row.trim());
      const headers = rows[0].split(',');

      // Check if CSV has the expected number of columns and correct column names
      if (headers.length !== expectedColumns.length || !expectedColumns.every((col) => headers.includes(col))) {
        alert('Invalid CSV format. Please make sure the CSV file has the correct headers.');
        return;
      }

      setFilename(file.name);

      const csvEntries = rows.slice(1).map((row) => {
        const values = row.split(',');
        return headers.reduce((entry, header, index) => {
          entry[header] = values[index];
          return entry;
        }, {});
      });

      const csvBangouList = csvEntries.map((entry) => entry['bangou']).filter(Boolean);
      const activeUserBangouList = activeuserList.map((employee) => employee.bangou).filter(Boolean);
      const unknownBangouList = csvBangouList.filter((bangou) => !activeUserBangouList.includes(bangou));
      setNotInReg(unknownBangouList);

      const missingBangouList = activeUserBangouList.filter((bangou) => !csvBangouList.includes(bangou));
      setNotInCSV(missingBangouList);

      const count = csvEntries.length - 1;
      setEntryCount(count);
      setFileUploaded(true);

      localStorage.setItem('k_filename', file.name);
      localStorage.setItem('k_notInReg', JSON.stringify(unknownBangouList));
      localStorage.setItem('k_notInCSV', JSON.stringify(missingBangouList));
      localStorage.setItem('k_entryCount', count.toString());
      localStorage.setItem('k_fileUploaded', 'true');

      // Make the API call to upload the CSV file
      Axios.post(`${baseURL}/api/kyuuyo`, formData)
        .then((response) => {
          console.log(response.data);
          // Handle successful response
        })
        .catch((error) => {
          console.log(error);
          // Handle error
        });
    };

    reader.readAsText(file);
  }

  function csv2json(csvArray) {
    var jsonArray = [];
		
    // 最初の行を「見出し」として変数に保存
    var keys = csvArray[0].split(',')
    var keyLength = keys.length

    // ２行目から「データ」として反復処理
    for (var lineIndex = 1; lineIndex < csvArray.length; lineIndex++) {
        // 行を取得
        var currentLine = csvArray[lineIndex]
	
        // 行が「カンマとスペースのみ」であれば次の行へ
        if (/^[,\s]*$/.test(currentLine)) {
            continue;
        }
	
        // 行をカンマ区切りで分けて各データを取得
        var items = currentLine.split(',');

        // 各要素を処理
        for (var itemIndex = 0; itemIndex < keyLength; itemIndex++) {
            var linObj = {}
            linObj[keys[itemIndex]] = items[itemIndex];				
            jsonArray.push(linObj);
        }
    }
	
    return jsonArray;
}

  const togglePopup = (employee) => {
    if (employee) {
      setEmployeeData(employee);
      setStatus(employee.status);
      setBangou(employee.bangou);
      setNyuusha(employee.nyuusha);
      setTaishoku(employee.taishoku);
      setEmail(employee.email);
    } else {
      setEmployeeData(null);
      setStatus('Active');
      setBangou('');
      setNyuusha('');
      setTaishoku('');
      setEmail('');
    }
    setShowPopup(!showPopup);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleNyuushaDateChange = (e) => {
    setNyuusha(e.target.value);
  };

  const handleTaishokuDateChange = (e) => {
    const taishokuDate = e.target.value;
    if (taishokuDate) {
      setStatus('NA');
    }
    setTaishoku(taishokuDate);
  };

  const handleBangouChange = (e) => {
    setBangou(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const updatedEmployee = {
      id: employeeData.id,
      status,
      bangou,
      nyuusha,
      taishoku,
      email,
    };

    Axios.put(`${baseURL}/api/update/employee`, updatedEmployee)
      .then((response) => {
        console.log(response.data);

        const updatedUserList = userList.map((user) =>
          user.id === updatedEmployee.id ? updatedEmployee : user
        );
        setUserList(updatedUserList);
        togglePopup(); // Close the popup
      })
      .catch((error) => {
        console.log(error);
        // Handle error
      });
  };

  return (
    <div className='bo'>
      <h4 className='fo-t'>従業員一覧</h4>
      <div className='bt-j'>
        <input
          type="search"
          className='bt-s'
          placeholder="検索"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />


        <input
          type="file"
          id="fileInput"
          style={{ display: 'none' }}
          onChange={handleChange}
        />
        <button
          style={{ float: 'right', marginBottom: '10px', marginRight: "20px" }}
          onClick={handleButtonClick}
          className="bt-c"
        >
          CSVアップロード
        </button>
        <div className='bt-s' style={{ display: 'inline' }}>
          <select value={searchMonth} onChange={handleMonthChange} className='datepicker rounded-left' >
            <option value="">月</option>
            {months.map((month) => (
              <option key={month.value} value={month.value}>
                {month.label}
              </option>
            ))}
          </select>

          <select value={searchYear} onChange={handleYearChange} className='datepicker rounded-right'>
            <option value="">年</option>
            {years.map((year) => (
              <option key={year.value} value={year.value}>
                {year.label}
              </option>
            ))}
          </select>


        </div>
        <div className="form-check form-switch" style={{ paddingLeft: "50px" }}>
          <input
            className="form-check-input"
            type="checkbox"
            id="flexSwitchCheckDefault"
            checked={showRetirementDate}
            onChange={(e) => setShowRetirementDate(e.target.checked)}
          />
          <label className="form-check-label" htmlFor="flexSwitchCheckDefault">すべてを表示</label>
        </div>
      </div>
      <div style={{ backgroundColor: "white", borderRadius: "10px", boxShadow: "3px 3px 6px rgba(90, 140, 168, 0.5)", paddingBottom: "30px", marginRight: "20px", marginBottom: "120px" }}>
        <table>
          <thead className='mi-d'>
            <tr>
              <th style={{ padding: "20px" }}>社員番号</th>
              <th style={{ padding: "20px" }}>Email</th>
              <th style={{ padding: "20px" }}>入社日</th>
              {showRetirementDate && <th style={{ padding: "20px" }}>退職日</th>}
              <th style={{ padding: "20px" }}>ステータス</th>
              <th style={{ padding: "20px" }}></th>
            </tr>
          </thead>
          <tbody>
            {filteredUserList.map((val, index) => {
              return (
                <tr key={val.id}>
                  <td style={{ padding: "10px" }}>{val.bangou}</td>
                  <td style={{ padding: "10px" }}>{val.email}</td>
                  <td style={{ padding: "10px" }}>{new Date(val.nyuusha).toLocaleDateString().split('T')[0]}</td>
                  {showRetirementDate && <td style={{ padding: "10px" }}>{new Date(val.taishoku).toLocaleDateString().split('T')[0]}</td>}
                  <td style={{ padding: "10px" }}>{val.status}</td>
                  <td style={{ padding: "10px" }}>
                    <button className="bt-h" onClick={() => togglePopup(val)} style={{ color: "#000000c6" }}>
                      編集
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {showPopup && (
        <Modal show={showPopup} onHide={togglePopup} centered>
          <Modal.Header closeButton>
            <Modal.Title>アカウント編集</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleFormSubmit}>
              <Form.Group controlId="status">
                <Form.Label>ステータス</Form.Label>
                <Form.Control
                  as="select"
                  value={status}
                  onChange={handleStatusChange}
                >
                  <option value="Active">Active</option>
                  <option value="NA">NA</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="nyuusha">
                <Form.Label>入社日</Form.Label>
                <Form.Control
                  type="date"
                  value={nyuusha === "0000-00-00" ? "" : moment(nyuusha).format('YYYY-MM-DD')}
                  onChange={handleNyuushaDateChange}
                />
              </Form.Group>
              <Form.Group controlId="taishoku">
                <Form.Label>退職日</Form.Label>
                <Form.Control
                  type="date"
                  value={taishoku === "0000-00-00" ? "" : moment(taishoku).format('YYYY-MM-DD')}
                  onChange={handleTaishokuDateChange}
                />
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={handleEmailChange} readOnly style={{ marginBottom: "20px", color: "#00000077" }}
                />
              </Form.Group>
              <Form.Control type="hidden" value={bangou} onChange={handleBangouChange} />
              <Button variant="primary" type="submit">
                保存
              </Button>{' '}
              <Button variant="secondary" onClick={togglePopup}>
                キャンセル
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};
