import React from 'react'
import { Link } from 'react-router-dom';

export const Missing = () => {
  return (
    <div className="bg">
      <div className="bt-t" style={{ backgroundColor: 'white', textAlign: 'left' }}>
        <div className="logo">
          <div className="left" style={{ color: 'rgba(16, 12, 196, 0.76)' }}>Q</div>
          <div className="right">
            <div style={{ color: 'rgba(16, 12, 196, 0.76)' }}>給与明細システム</div>
          </div>
        </div>
      </div>
      <div className="fcon" style={{ width: '40%', margin: '20px auto', padding: '20px 7%' }}>
        <section style={{ textAlign: 'center', padding: '20px 7%' }}>
      
          
         
            <h3>Page Missing</h3>
            <span className="line">
              <Link to="..">Back</Link>
            </span>
         
        </section>
      </div>
    </div>
  )
}
