import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import useAuth from '../useAuth';

export const EDashboard = () => {

  const navigate = useNavigate();
  const { setAuth } = useAuth();

  const handleLogout = () => {
    setAuth(null); 
    navigate('/login'); 

  };

  return (
    <div className='bg'>
     <div className='bt-t' style={{ backgroundColor: 'white', textAlign: "left", display: "flex", justifyContent: "space-between", marginBottom: ' 10px'}}>
        <div className="logo">
          <div className="left" style={{ color: "rgba(16, 12, 196, 0.76)" }}>Q</div>
          <div className="right">
            <div style={{ color: "rgba(16, 12, 196, 0.76)" }}>給与明細システム</div>
          </div>
        </div>
        <Link to= '/login' className='btn btn-link' onClick={handleLogout} style={{ fontSize: "30px" }}><i className="fa-solid fa-arrow-right-from-bracket"></i><p style={{ fontSize: "10px" }}>ログアウト</p></Link>
      </div>
      <Link to="/employee/kyuuyo"><button className='btn btn-primary db-button'>給与明細</button></Link>
      <Link to="/employee/bonus"><button className='btn btn-primary db-button'>ボーナス</button></Link>
      <Link to="/employee/gensen"><button className='btn btn-primary db-button'>源泉徴収票</button></Link>
    </div>
  )
}
