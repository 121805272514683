import './App.css';
import React from 'react'
import { Routes, Route } from "react-router-dom";
import { Dashboard } from './components/Dashboard';
import { Home } from './components/Home';
import { Juugyouin } from './components/Juugyouin';
import { Kyuuyo } from './components/Kyuuyo';
import { Bonus } from './components/Bonus';
import { Gensen } from './components/Gensen';
import { Seikyuusho } from './components/Seikyuusho';
import { SeikyuushoShousai } from './components/SeikyuushoShousai';
import { KyuuyoRireki } from './components/KyuuyoRireki';
import { BonusRireki } from './components/BonusRireki';
import { GensenRireki } from './components/GensenRireki';
import Register from './components/Register';
import Login from './components/Login';
import { EDashboard } from './components/employee/EDashboard';
import { EKyuuyo } from './components/employee/EKyuuyo';
import { EBonus } from './components/employee/EBonus';
import { EGensen } from './components/employee/EGensen';
import Layout from './components/Layout';
import RequireAuth from './components/RequireAuth';
import { Missing } from './components/Missing';
import { Unauthorized } from './components/Unauthorized';


function App() {
  return (
    <Routes>
      <Route path='/' element={<Layout />} >
        <Route path='/register' element={<Register />} />
        <Route path='/login' element={<Login />} />
        <Route path='/unauthorized' element={<Unauthorized />} />


        <Route element={<RequireAuth allowedRoles={[2000]} />}>
          <Route path='/employee/dashboard' element={<EDashboard />} />
          <Route path='/employee/kyuuyo' element={<EKyuuyo />} />
          <Route path='/employee/bonus' element={<EBonus />} />
          <Route path='/employee/gensen' element={<EGensen />} />
        </Route>

        <Route element={<RequireAuth allowedRoles={[3000]} />}>
          <Route path='/' element={<Dashboard />} >
            <Route index element={<Home />} />
            <Route path='/home' element={<Home />} />
            <Route path='/juugyouin' element={<Juugyouin />} />
            <Route path='/kyuuyo' element={<Kyuuyo />} />
            <Route path='/kyuuyo/rireki' element={<KyuuyoRireki />} />
            <Route path='/bonus' element={<Bonus />} />
            <Route path='/bonus/rireki' element={<BonusRireki />} />
            <Route path='/gensen' element={<Gensen />} />
            <Route path='/gensen/rireki' element={<GensenRireki />} />
            <Route path='/seikyuusho' element={<Seikyuusho />} />
            <Route path='/seikyuusho/shousai' element={<SeikyuushoShousai />} />
          </Route>
        </Route>
        <Route path='*' element={<Missing />} />

      </Route>
    </Routes>

  );
}

export default App;
