import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import baseURL from './config.js';
import moment from 'moment'


export const Home = () => {

  const [userList, setUserList] = useState([])
  const [activeuserList, setActiveUserList] = useState([])
  const [koushinList, setKoushinList] = useState([])


  useEffect(() => {
    Axios.get(`${baseURL}/api/employee`)
      .then((response) => {
        const { employee, activeEmployee } = response.data;
        setUserList(employee);
        setActiveUserList(activeEmployee);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    Axios.get(`${baseURL}/api/sousa`)
      .then((response) => {
        const { sousa } = response.data;
        setKoushinList(sousa);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  return (
    <div className='bo'>
      <h4 className='fo-t'>ホーム</h4>
      <div className='row bt-r' style={{ width: '35%', backgroundColor: 'white', marginLeft: '1px', padding: '5px' }}>
        <div className='col'>
          <div className='row'>
            <div className='col-2'><h3><i className="fa-regular fa-user" style={{ color: "rgba(16, 12, 196, 0.76)" }}></i></h3></div>
            <div className='col-10'>　総従業員<br />　{userList.length}</div>
          </div>
        </div>

        <div className='col-1'><h2 style={{ fontWeight: 'lighter' }}>|</h2></div>

        <div className='col'>
          <div className='row'>
            <div className='col-10'>　<i className="fa-solid fa-circle" style={{ color: "#19E415" }}></i>アクティブ<br />　{activeuserList.length}</div>
          </div>
        </div>

      </div>

      <div>
        <h4 className='fo-t'>操作ログ</h4>
        <div style={{ textAlign: "center", marginBottom: "120px" }}>
          <table style={{ borderCollapse: "separate", borderSpacing: "0px 10px" }}>
            <thead>
              <tr style={{ backgroundColor: "transparent" }}>
                <th>更新詳細</th>
                <th>日付</th>
              </tr>
            </thead>
            { koushinList.length > 0 && (
            <tbody style={{ backgroundColor: "white" }}>
              {koushinList.slice(0, 15).map((val, index) => {
                return (
                  <tr key={val.id} style={{ backgroundColor: "white", boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}>
                    <td style={{ padding: "10px" }}>{val.shousai}</td>
                    <td style={{ padding: "10px" }}>{moment(val.date).format('YYYY-MM-DD HH:mm:ss')}</td>
                  </tr>
                );
              })}
            </tbody>
          )}
          </table>
        </div>
      </div>
    </div>
  )
}
