import React, { useContext, useEffect, useRef, useCallback } from "react";
import { Outlet } from "react-router-dom";
import AuthContext from "./AuthProvider";

const Layout = () => {
  const { setAuth } = useContext(AuthContext);
  const logoutTimerRef = useRef(null);

  const resetLogoutTimer = useCallback(() => {
    // Clear existing timer
    if (logoutTimerRef.current) {
      clearTimeout(logoutTimerRef.current);
    }

    // Set new timer to logout after 15 minutes
    logoutTimerRef.current = setTimeout(() => {
      setAuth({ token: null, role: null, bangou: null });
      localStorage.removeItem("auth");
    }, 15 * 60 * 1000); // 15 minutes in milliseconds
  }, [setAuth]);

  useEffect(() => {
    const handleLogout = () => {
      resetLogoutTimer();
    };

    // Start initial timeout
    resetLogoutTimer();

    document.addEventListener("mousemove", handleLogout);
    document.addEventListener("keydown", handleLogout);

    return () => {
      document.removeEventListener("mousemove", handleLogout);
      document.removeEventListener("keydown", handleLogout);
      clearTimeout(logoutTimerRef.current);
    };
  }, [resetLogoutTimer]);

  return (
    <main>
      <Outlet />
    </main>
  );
};

export default Layout;