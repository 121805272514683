import React, { useState, useEffect } from 'react';
import { Link, useNavigate  } from 'react-router-dom';
import Axios from 'axios';
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import useAuth from '../useAuth';
import baseURL from '../config.js';

export const EBonus = () => {
  const [rireki, setRireki] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const { setAuth } = useAuth();

  const authItem = localStorage.getItem('auth');
  let bangou = '';
  if (authItem) {
    const authData = JSON.parse(authItem);
    bangou = authData.bangou;
  } else {
    console.log("No 'auth' item found in localStorage");
  }

  useEffect(() => {

    const mostRecentItem = rireki[rireki.length - 1];
    
    setSelectedDate(mostRecentItem?.hakkoudate || '');
  
  }, [rireki]);

  useEffect(() => {
    const payload = {
      bangou: bangou,
    };
  
    Axios.get(`${baseURL}/employee/bonus/rireki`,{ params: payload })
      .then((response) => {
        const { rireki } = response.data;
        setRireki(rireki);


      })
      .catch((error) => {
        console.log(error);
      });
  }, [bangou]);

  const navigateToPrevMonth = () => {
    if (selectedDate) {
      const selectedIndex = rireki.findIndex((item) => item.hakkoudate === selectedDate);
      if (selectedIndex > 0) {
        setSelectedDate(rireki[selectedIndex - 1].hakkoudate);
      }
    }
  };

  const navigateToNextMonth = () => {
    if (selectedDate) {
      const selectedIndex = rireki.findIndex((item) => item.hakkoudate === selectedDate);
      if (selectedIndex < rireki.length - 1) {
        setSelectedDate(rireki[selectedIndex + 1].hakkoudate);
      }
    }
  };

  const downloadPDF = () => {
    const capture = document.getElementsByClassName('fcon')[0];
    setLoader(true);
    html2canvas(capture).then((canvas) => {
      const imgData = canvas.toDataURL('img.//png');
      const doc = new jsPDF('p', 'mm', 'a4');
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight);
      setLoader(false);
      doc.save('hakkourireki.pdf');
    });
  };

  const handleLogout = () => {
    setAuth(null); 
    navigate('/login'); 
  };

  const handleDateSelect = (event) => {
    setSelectedDate(event.target.value);
  };

  return (
    <div>
      {rireki.length > 0 && (
        <div className='bg'>
          <div
            className='bt-t'
            style={{ backgroundColor: 'white', textAlign: 'left', display: 'flex', justifyContent: 'space-between', marginBottom: ' 10px' }}
          >
            <div className='logo'>
              <div className='left' style={{ color: 'rgba(16, 12, 196, 0.76)' }}>Q</div>
              <div className='right'>
                <div style={{ color: 'rgba(16, 12, 196, 0.76)' }}>給与明細システム</div>
              </div>
            </div>
            <Link to='/login' className='btn btn-link' onClick={handleLogout} style={{ fontSize: '30px' }}>
              <i className='fa-solid fa-arrow-right-from-bracket'></i>
              <p style={{ fontSize: "10px" }}>ログアウト</p>
            </Link>
          </div>
          <div>
            <div className='bo'>
              <div className='row' style={{ width: '70%', margin: '20px auto 20px auto' }}>
                <div className='col-4'>
                  <Link to='/employee/dashboard'>
                    <button className='bt-h'>戻る</button>
                  </Link>
                </div>
                <div className='col-4' style={{ textAlign: 'center' }}>
                  <h5>社員番号：</h5>
                  <h3>345-2423</h3>
                </div>
                <div className='col-4' style={{ textAlign: 'right' }}>
                  <button className='pdf bt-h' onClick={downloadPDF} disabled={loader}>
                    {loader ? <span>Downloading</span> : <span>PDF</span>}
                  </button>
                </div>
              </div>

              <div className='fcon'>
                <select value={selectedDate} onChange={handleDateSelect}>
                  <option value="">Select a date</option>
                  {rireki.map((item) => (
                    <option key={item.hakkoudate} value={item.hakkoudate}>
                      {new Date(item.hakkoudate).toLocaleDateString().split('T')[0]}
                    </option>
                  )).reverse()}
                </select>
                <h2 style={{ textAlign: 'center' }}>{selectedDate && new Date(selectedDate).toLocaleDateString().split('T')[0]}</h2>
                <div>
                  <h5 className='e-ti e-pr'>支給</h5>
                  <div className='row e-pr' style={{ width: '70%' }}>
                    <h5 className='col-6'>基本給</h5>
                    <h5 className='col-6'>￥{selectedDate && rireki.find((item) => item.hakkoudate === selectedDate).kihon}</h5>
                  </div>
                  <div className='row e-pr' style={{ width: '70%' }}>
                    <h5 className='col-6'>時間外手当</h5>
                    <h5 className='col-6'>￥{selectedDate && rireki.find((item) => item.hakkoudate === selectedDate).teate}</h5>
                  </div>
                  <h5 style={{ paddingLeft: '60%', marginTop: '20px', marginBottom: "20px" }}>総支給額　￥{selectedDate && parseInt(rireki.find(item => item.hakkoudate === selectedDate).kihon) + parseInt(rireki.find(item => item.hakkoudate === selectedDate).teate)}</h5>
                  <h5 className='e-ti e-pr'>控除</h5>
                  <div className='row e-pr' style={{ width: "70%" }}>
                    <h5 className='col-6'>健康年金</h5>
                    <h5 className='col-6'>￥{selectedDate && rireki.find(item => item.hakkoudate === selectedDate).kenkou}</h5>
                </div>
                  <div className='row e-pr' style={{ width: "70%" }}>
                    <h5 className='col-6'>厚生年金</h5>
                    <h5 className='col-6'>￥{selectedDate && rireki.find(item => item.hakkoudate === selectedDate).nenkin}</h5>
                  </div>
                  <div className='row e-pr' style={{ width: "70%" }}>
                    <h5 className='col-6'>雇用保険</h5>
                    <h5 className='col-6'>￥{selectedDate && rireki.find(item => item.hakkoudate === selectedDate).koyou}</h5>
                </div>
                  <h5 style={{ paddingLeft: "60%", marginTop: "20px", marginBottom: "20px" }}>控除計　￥{selectedDate && parseInt(rireki.find(item => item.hakkoudate === selectedDate).kenkou) + parseInt(rireki.find(item => item.hakkoudate === selectedDate).nenkin) + parseInt(rireki.find(item => item.hakkoudate === selectedDate).koyou)}</h5>
                  <div className='row e-ti e-pr'>
                    <h4 className='col-8'>合計</h4>
                    <h4 className='col-4'>￥{selectedDate && parseInt(rireki.find(item => item.hakkoudate === selectedDate).kihon) + parseInt(rireki.find(item => item.hakkoudate === selectedDate).teate) + parseInt(rireki.find(item => item.hakkoudate === selectedDate).kenkou) + parseInt(rireki.find(item => item.hakkoudate === selectedDate).nenkin) + parseInt(rireki.find(item => item.hakkoudate === selectedDate).koyou)}</h4>
                </div>
                </div>
                <div style={{ textAlign: "center" }}>
                <button className='btn' style={{ fontWeight: 'bolder' }} onClick={navigateToPrevMonth}>
                  ＜
                </button>
                <button className='btn' style={{ fontWeight: 'bolder' }} onClick={navigateToNextMonth}>
                  ＞
                </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};