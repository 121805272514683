import React, { useState, useEffect, useCallback } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import useAuth from './useAuth';

export const Dashboard = () => {
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const location = useLocation();

  const getActiveButtonFromURL = useCallback(() => {
    const path = location.pathname;
    const activeButton = path.substring(1);
    return activeButton || 'home';
  }, [location]);
  const [activeButton, setActiveButton] = useState(getActiveButtonFromURL());

  const handleLogout = () => {
    localStorage.clear()
    setAuth(null);
    navigate('/login');
  };

  useEffect(() => {
    setActiveButton(getActiveButtonFromURL());
  }, [getActiveButtonFromURL, location]);
  
  return (
    <div className='bg'>
      <div className='bt-t' style={{ backgroundColor: 'white', textAlign: "left", display: "flex", justifyContent: "space-between", marginBottom: ' 10px'}}>
        <div className="logo">
          <div className="left" style={{ color: "rgba(16, 12, 196, 0.76)" }}>Q</div>
          <div className="right">
            <div style={{ color: "rgba(16, 12, 196, 0.76)" }}>給与明細システム</div>
          </div>
        </div>
        <Link to= '/login' className='btn btn-link' onClick={handleLogout} style={{ fontSize: "30px" }}><i className="fa-solid fa-arrow-right-from-bracket"></i><p style={{ fontSize: "10px" }}>ログアウト</p></Link>
        
      </div>

      <div className='row'>
        <div className='col-2 bt-v' style={{ backgroundColor: 'white', height: '100vh' }}>
          <ul className="nav nav-pills flex-column mb-auto">
            <li>
              <h4 style={{ textAlign: "center" }}>管理画面</h4>
              <hr />
            </li>
            <li className="nav-item">
              <Link to="home">
                <button
                  className={`bo-d btn btn-default ${activeButton === 'home' ? 'active' : ''}`}
                  data-toggle="button"
                  autoComplete="off"
                  style={{ width: "100%" }}
                >
                  <i className="fa-solid fa-house" style={{ color: "rgba(16, 12, 196, 0.76)" }}></i> ホーム
                </button>
              </Link>
            </li>
            <li>
              
              <Link to="juugyouin">
                <button
                  className={`bo-d ${activeButton === 'juugyouin' ? 'active' : ''}`}
                  data-toggle="button"
                  autoComplete="off"
                  style={{ width: "100%" }}
                 
                >
                  <i className="fa-solid fa-user" style={{ color: "rgba(16, 12, 196, 0.76)" }}></i> 従業員
                </button>
              </Link>
            </li>
            <li>
              <Link to="kyuuyo">
                <button
                  className={`bo-d btn btn-default ${activeButton === 'kyuuyo' ? 'active' : ''}`}
                  data-toggle="button"
                  autoComplete="off"
                  style={{ width: "100%" }}
           
                >
                  <i className="fa-solid fa-hand-holding-dollar" style={{ color: "rgba(16, 12, 196, 0.76)" }}></i> 給与明細
                </button>
              </Link>
            </li>
            <li>
              <Link to="bonus">
                <button
                  className={`bo-d btn btn-default ${activeButton === 'bonus' ? 'active' : ''}`}
                  data-toggle="button"
                  autoComplete="off"
                  style={{ width: "100%" }}
   
                >
                  <i className="fa-solid fa-sack-dollar" style={{ color: "rgba(16, 12, 196, 0.76)" }}></i> ボーナス
                </button>
              </Link>
            </li>
            <li>
              <Link to="gensen">
                <button
                  className={`bo-d btn btn-default ${activeButton === 'gensen' ? 'active' : ''}`}
                  data-toggle="button"
                  autoComplete="off"
                  style={{ width: "100%" }}
             
                >
                  <i className="fa-solid fa-coins" style={{ color: "rgba(16, 12, 196, 0.76)" }}></i> 源泉徴収票
                </button>
              </Link>
            </li>
            <li>
              <Link to="seikyuusho">
                <button
                  className={`bo-d btn btn-default ${activeButton === 'seikyuusho' ? 'active' : ''}`}
                  data-toggle="button"
                  autoComplete="off"
                  style={{ width: "100%" }}
               
                >
                  <i className="fa-solid fa-file-invoice" style={{ color: "rgba(16, 12, 196, 0.76)" }}></i> 請求書
                </button>
              </Link>
            </li>
            <div className="animation start-home"></div>
          </ul>
        </div>
        <div className='col-10'><Outlet /></div>
      </div>
    </div>

  )
}
