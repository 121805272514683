import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import baseURL from './config.js';

export const BonusRireki = () => {
    const [activeuserList, setActiveUserList] = useState([]);
    const [rireki, setRireki] = useState([]);
    const [currentMonthIndex, setCurrentMonthIndex] = useState(0);

    useEffect(() => {
        Promise.all([
          Axios.get(`${baseURL}/api/employee`),
          Axios.get(`${baseURL}/api/bonus/rireki`)
        ])
          .then(([employeeResponse, rirekiResponse]) => {
            const { activeEmployee } = employeeResponse.data;
            const { rireki } = rirekiResponse.data;
            setActiveUserList(activeEmployee);
        
            // Filter rireki based on hakkoudate
            const filteredRireki = rireki.filter((item) => {
                const hakkoudatePassed = new Date(item.hakkoudate) < new Date();
                return hakkoudatePassed;
            });
      
            setRireki(filteredRireki);
      
            // Find the latest month in the filtered rireki array
            const latestMonth = filteredRireki.reduce((latest, current) => {
              if (!latest || current.hakkoudate > latest.hakkoudate) {
                return current;
              }
              return latest;
            }, null);
      
            // Find the index of the latest month in the filtered rireki array
            const latestMonthIndex = filteredRireki.findIndex(
                (item) => item.hakkoudate === latestMonth.hakkoudate
                );
      
            // Set the current month index to the index of the latest month
            setCurrentMonthIndex(latestMonthIndex);
          })
          .catch((error) => {
            console.log(error);
          });
      }, []);


    const navigateToPrevMonth = () => {
        if (currentMonthIndex > 0) {
            setCurrentMonthIndex((prevIndex) => prevIndex - 1);
        }
    };

    const navigateToNextMonth = () => {
        if (currentMonthIndex < rireki.length - 1) {
            setCurrentMonthIndex((prevIndex) => prevIndex + 1);
        }
    };

    return (
        <div className='bo'>
            <h4 className='fo-t'>ボーナス履歴</h4>


            <div className='row bt-r' style={{ width: '35%', backgroundColor: 'white', marginLeft: '1px', padding: '5px' }}>
                <div className='col'>
                    <div className='row'>
                        <div className='col-2'><h3><i className="fa-regular fa-user" style={{ color: "rgba(16, 12, 196, 0.76)" }}></i></h3></div>
                        <div className='col-10'>　<i className="fa-solid fa-circle" style={{ color: "#19E415" }}></i>アクティブ<br />　{activeuserList.length}</div>
                    </div>
                </div>

                <div className='col-1'><h2 style={{ fontWeight: 'lighter' }}>|</h2></div>

                <div className='col'>
                    <div className='row'>
                        <div className='col-2'><h3><i className="fa-solid fa-hand-holding-dollar" style={{ color: "rgba(16, 12, 196, 0.76)" }}></i></h3></div>
                        <div className='col-10'>　賞与書数<br />　{rireki[currentMonthIndex]?.entryCount}</div>
                    </div>
                </div>

            </div>

            {/* Render the current month's data */}
            {rireki.length > 0 && (
                <div style={{ textAlign: 'center' }}>
                    <div className='row'>
                        <h5 className='col-4' style={{ textAlign: 'left' }}>{rireki[currentMonthIndex].filename}</h5>
                        <div className='col-4' style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                            <button className='btn' style={{ fontWeight: 'bolder' }} onClick={navigateToPrevMonth}>＜</button>
                            <h2 style={{ textAlign: 'center', display: 'inline' }}>{new Date(rireki[currentMonthIndex].hakkoudate).toLocaleDateString().split('T')[0]}</h2>
                            <button className='btn' style={{ fontWeight: 'bolder' }} onClick={navigateToNextMonth}>＞</button>
                        </div>
                    </div>
                    {rireki[currentMonthIndex].message3 !== null && rireki[currentMonthIndex].message3 !== "" ? (
                        <div className='bt-r csvupload'>
                            <p className='success'>{rireki[currentMonthIndex].message3}</p>
                        </div>
                    ) : (
                        <div className='bt-r csvupload'>
                            <p className='warning'>データとの間に不一致が見つかりました:</p>
                            {rireki[currentMonthIndex].message1 !== null && rireki[currentMonthIndex].message1 !== "" && (
                                <p className='warning'>{rireki[currentMonthIndex].message1}</p>
                            )}
                            {rireki[currentMonthIndex].message2 !== null && rireki[currentMonthIndex].message2 !== "" && (
                                <p className='warning'>{rireki[currentMonthIndex].message2}</p>
                            )}
                        </div>
                    )}

                </div>
            )}

            {rireki.length === 0 && (
                <div style={{ textAlign: 'center' }}>
                    <div className='row'>
                        <h5 className='col-4' style={{ textAlign: 'left', display: "inline" }}>No History</h5>
                        <div className='col-4' style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
                            <button className='btn' style={{ fontWeight: 'bolder' }}>＜</button>
                            <h2 style={{ textAlign: 'center', display: 'inline' }}>*No History*</h2>
                            <button className='btn' style={{ fontWeight: 'bolder' }}>＞</button>

                        </div>
                        <div className='bt-r csvupload'>
                            <h3>*Empty*</h3>
                        </div>
                    </div>
                </div>
            )}
            <div style={{ marginBottom: '60px' }}>
                <Link to="/bonus" style={{ textDecoration: 'underline', alignSelf: 'flex-start' }}> 発行画面に戻る</Link>
            </div>
        </div>
    );
};
